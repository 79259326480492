.solution-container {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  margin-top: 10vh;
}

.solution-section-1-container {
  height: auto;
  width: 100%;
  /* background: rgb(9, 179, 52); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.solution-section-1-top-container {
  height: auto;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.solution-heading-1 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: left;
}

.solution-content {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 400;
  font-size: var(--font-size-3);
  color: #000000;
}

.solution-section-1-bottom-container {
  height: auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
}

.solution-section-2-container {
  height: 100vh;
  width: 100%;
  /* background: rgb(179, 131, 9); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.solution-content-2-button-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.solution-button {
  background: #e64848;
  border-radius: 7px;
  border: none;
  padding: 5px 20px 5px 20px;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-4);
  color: var(--secondary-font-color);
  cursor: pointer;
}

.solution-button:active {
  background: #ffffff;
  color: var(--primary-font-color);
}

.solution-section-3-container {
  height: fit-content;
  width: 100%;
  /* background: rgb(179, 131, 9); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.solution-section-2-top-container {
  height: 30%;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-section-3-top-container {
  height: 20%;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-section-2-top-warp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.solution-heading-2 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: center;
}

.solution-content-2 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 400;
  font-size: var(--font-size-3);
  color: #000000;
  text-align: center;
}

.solution-section-2-bottom-container {
  height: 70%;
  width: 100%;
  /* background: rgb(222, 4, 4); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.solution-section-3-bottom-container {
  height: 80%;
  width: 100%;
  /* background: rgb(222, 4, 4); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

@media only screen and (max-device-width: 760px) {
  .solution-container{
    height: auto;
  }
  .solution-section-1-container{
    height: auto;
    gap: 5vh;
  }
  .solution-section-1-top-container {
    justify-content: center;
    text-align: center;
  }
  
  .solution-heading-1{
    text-align: center;
  }
  .solution-section-1-bottom-container{
    height: fit-content;
    flex-direction: column;
    
  }
  .solution-section-2-container{
    height: fit-content;
    gap: 5vh;
  }
  .solution-section-2-bottom-container{
    flex-direction: column;
  }
  .solution-section-3-container{
    height: fit-content;
  }
}