.template-main-container {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.template-heading-1 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-content-1);
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 5%;
  color: var(--font-color-3);
}

.template-heading-2 {
  font: var(--primary-font);
  font-style: normal;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 300;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
}

.template-heading-3 {
  font: var(--primary-font);
  font-style: normal;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 300;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
}

.test {
  border-style: hidden hidden hidden solid;
  /* border-radius: 25px; */
  border-width: 10px;
  border-color: rgb(0, 0, 0);
  background-color: rgb(221, 221, 221);
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}

.template-heading-4 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-1);
  padding: 2%;
  color: var(--font-color-3);
}
