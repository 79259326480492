:root {
  /* background-color: #211c55; */
  font-size: 1.1vw;
  width: 100%;

  --primary-background-color: #ffffff;
  --primary-container-color: #811bef;
  --secondary-container-color: #ecf0f1;
  --highlighter-color: #511ba1;
  --primary-font-color: #000000;
  --secondary-font-color: #ffffff;
  --primary-button-color: #f23560;
  --secondary-button-color: #fefeff;
  --neomorphism: 12px 12px 16px 0 #1d194b, -8px -8px 12px 0 #251f5f;

  --primary-font: "poppins";

  --font-size-1: 2rem;
  --font-size-2: 1.5rem;
  --font-size-3: 1rem;
  --font-size-4: 0.8rem;
  --font-size-5: 0.5rem;

  --font-size-heading-1: 5rem;
  --font-size-content-1: 3rem;
  --font-size-content-2: 2.5rem;
}
@media only screen and (max-device-width: 760px) {
  :root {
    font-size: 2vw;
  }
}
@media only screen and (max-device-width: 420px) {
  :root {
    font-size: 3vw;
    text-align: center;
  }
}
