.price-container {
  height: auto;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 10vh;
}

.price-main-container {
  height: auto;
  width: 100%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10vh;
}

.price-top-container {
  height: 40%;
  width: 85%;
  /* background: rgb(135, 20, 20); */
}

.price-top-container-warp {
  height: 90%;
  width: 100%;
  /* background: rgb(60, 172, 29); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.scroll{
  height: fit-content;
  width: fit-content;
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
}

.price-page-image{
  height: 50%;
}

.price-heading-1 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
}

.pricing-content {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 400;
  font-size: var(--font-size-3);
  color: #000000;
  text-align: center;
}

.price-bottom-container {
  height: 50%;
  width: 85%;
  /* background: rgb(20, 135, 104); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5vw;
}

@media only screen and (max-device-width: 760px) {
  .price-container {
    height: fit-content;
  }
  .price-main-container{
    height: fit-content;
    gap: 5vh;
  }
  .scroll{
    display: none;
  }
  .price-bottom-container{
    height: fit-content;
    padding-bottom: 5vh;
  }
}