.home-container {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8vh;
  /* gap: 10vh; */
  /* background: #000; */
}

.home-main-container {
  height: 92vh;
  width: 100%;
  /* background: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-left-container {
  height: 100%;
  width: 50%;
  /* background: rgb(174, 5, 5); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-left-container-warp {
  height: 50%;
  width: 85%;
  /* background: rgb(5, 174, 112); */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.home-heading-1 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-heading-1);
  color: #000000;
}

.home-heading-2 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-content-1);
  color: var(--font-color-3);
}

.home-heading-3 {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-2);
  color: var(--font-color-3);
}

.home-getsupport {
  background: #e64848;
  border-radius: 7px;
  border: none;
  padding: 5px 20px 5px 20px;
  font: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-4);
  color: var(--secondary-font-color);
  cursor: pointer;
  text-decoration: none;
}

.why-us {
  height: 25%;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: var(--primary-background-color);
}

.title {
  font: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-content-1);
  color: var(--font-color-3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  width: 97.5%;
  height: auto;
  background-color: var(--primary-background-color);
}

.row-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  width: 90%;
  height: auto;
  /* background-color: var(--primary-background-color) */
  background: #000;
}

.row-space {
  width: 10vw;
  height: auto;
}

.column-space {
  height: 10vh;
  width: auto;
  /* background: #000; */
}

.column-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  justify-items: center;
  width: 90%;
  height: auto;
  background-color: var(--primary-background-color);
}

.read-more {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-items: center;
  width: 100%;
  height: auto;
  background-color: var(--primary-background-color);
}

.shrink-container {
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  background-color: var(--primary-background-color);

  /* background: rgb(5, 174, 174); */
}

.expand-container {
  background-color: var(--primary-background-color);
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  /* background: rgb(5, 174, 174); */
}

.suspense_style{
  position: fixed; /* or absolute */
  top: 50%;
  left: 45%;
  font: var(--primary-font);
  font-weight: 500;
  color: var(--primary-font-color);
}