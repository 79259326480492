.project-container {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8vh;
}

.project-section-1-container {
  height: 92vh;
  width: 100%;
  /* background: rgb(9, 179, 52); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

.project-section-1-left {
  height: 100%;
  width: 50%;
  /* background: rgb(104, 202, 19); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-soFar-image {
  height: 80%;
}

.project-section-1-right {
  height: 100%;
  width: 50%;
  /* background: rgb(37, 19, 202); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project-section-1-right-top-container {
  height: 30%;
  width: 100%;
  /* background: rgb(31, 156, 151); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.project-section-1-right-top-warp {
  height: 80%;
  width: 90%;
  /* background: rgb(206, 199, 199); */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.project-heading-1 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: right;
}

.project-section-1-right-bottom-container {
  height: 70%;
  width: 100%;
  /* background: rgb(158, 10, 10); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-section-1-right-bottom-warp {
  height: 80%;
  width: 90%;
  /* background: #000; */
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}

.project-section-2-container {
  height: 50%;
  width: 100%;
  /* background: rgb(136, 12, 127); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.project-section-2-top-container {
  height: 20%;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.project-heading-2 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: left;
}

.project-section-2-bottom-container {
  height: auto;
  width: 90%;
  /* background: rgb(222, 4, 4); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  padding-bottom: 5vh;
}
.project-section-3-container {
  height: 50%;
  width: 100%;
  /* background: rgb(136, 12, 127); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.project-section-3-top-container {
  height: 20%;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.project-heading-3 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: left;
}

.project-section-3-bottom-container {
  height: auto;
  width: 90%;
  background: #ffffff;
/* box-shadow: inset 20px 20px 60px #d9d9d9,
            inset -20px -20px 60px #ffffff; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  padding: 5vh;
  border-radius: 10px;
}
.project-section-4-container {
  height: 50%;
  width: 100%;
  /* background: rgb(136, 12, 127); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5vh;
}

.project-section-4-top-container {
  height: 20%;
  width: 90%;
  /* background: rgb(67, 171, 131); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.project-heading-4 {
  font: var(--primary-font);
  /* font-style: normal; */
  font-weight: 900;
  font-size: var(--font-size-1);
  color: #000000;
  text-align: left;
}

.project-section-4-bottom-container {
  height: auto;
  width: 90%;
  /* background: rgb(222, 4, 4); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
  padding-bottom: 5vh;
}

@media only screen and (max-device-width: 760px) {
  .project-section-1-container {
    flex-direction: column-reverse;
  }
  .project-section-1-right-top-container {
    align-items: center;
  }
  .project-section-1-right-top-warp {
    justify-content: center;
  }
  .project-heading-1,
  .project-heading-2,
  .project-heading-3,
  .project-heading-4 {
    text-align: center;
  }
  .project-section-1-right-bottom-warp {
    justify-content: center;
    flex-wrap: wrap;
  }
  .project-section-1-right {
    width: 100%;
  }
  .project-section-2-top-container,
  .project-section-3-top-container,
  .project-section-4-top-container {
    justify-content: center;
    margin-top: 5vh;
  }
  .project-section-2-bottom-container,.project-section-3-bottom-container{
    justify-content: center;
  }
  .project-section-2-container,
  .project-section-3-container,
  .project-section-4-container {
    height: fit-content;
    gap: 5vh;
  }
  .project-section-2-bottom-container,
  .project-section-3-bottom-container,
  .project-section-4-bottom-container {
    padding-bottom: 2vh;
   
  }
}
